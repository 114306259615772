<template>
  <div class="w-full pt-0 p-2">
    <div class="w-full flex justify-start items-center text-center text-zinc-400 border p-2 rounded-xl border-white/20 bg-zinc-900">
      <div class="grid grid-cols-4 w-full z-50 relative">
        <NuxtLink :to="item.path" v-for="item in pinnedNav">
          <button :class="[route.name === item.name ? 'text-turquoise-500  ' : '']" class="tracking-wide">
            {{ item.title }}
          </button>
        </NuxtLink>
      </div>

      <ClientOnly>
        <HeadlessPopover class="relative">
          <HeadlessPopoverButton class="w-8 h-8 rounded-xl bg-turquoise-500/10 ui-open:bg-turquoise-500 flex justify-center items-center z-50 relative" aria-label="view links">
            <!-- <span>Solutions</span> -->
            <!-- <ChevronDownIcon class="h-5 w-5" aria-hidden="true" /> -->
            <Icon name="flowbite:chevron-down-outline" class="text-turquoise-500 text-xl ui-open:text-turquoise-1000 rotate-180 ui-open:rotate-0 duration-300" />
          </HeadlessPopoverButton>

          <transition enter-active-class="transition duration-500" enter-from-class="opacity-0 translate-y-full" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition duration-500" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-full">
            <HeadlessPopoverPanel class="absolute -right-4 p-2 top-0 pb-4 z-40 flex w-screen max-w-max">
              <div class="w-full flex-auto overflow-hidden rounded-xl bg-zinc-900 border-zinc-800 border text-left max-h-[80vh] overflow-y-auto -translate-y-full -mt-8">
                <div class="p-4">
                  <section class="mb-2">
                    <h6 class="text-white px-2 py-1 font-semibold">App</h6>

                    <NuxtLink to="/app">
                      <HeadlessPopoverButton class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100">Home</HeadlessPopoverButton>
                    </NuxtLink>

                    <NuxtLink :to="nav.path" v-for="nav in navItems">
                      <HeadlessPopoverButton class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100">
                        {{ nav.title }}
                      </HeadlessPopoverButton>
                    </NuxtLink>
                  </section>

                  <section class="mb-2">
                    <h6 class="text-white px-2 py-1 font-semibold">OXB Token</h6>

                    <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-between items-center group">
                      <!-- <span>Address: {{ OXB_V2_ADDRESS.substring(0, 10) }}...</span> -->
                      <span>Address: 0x...</span>

                      <div class="flex justify-center items-center space-x-2">
                        <template v-if="!copied">
                          <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copy</span>
                          <Icon name="mdi:content-copy" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                        </template>

                        <template v-else>
                          <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copied</span>
                          <Icon name="mdi:check" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                        </template>
                      </div>
                    </button>

                    <a href="#" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <!-- <img class="opacity-70 w-5 h-5 p-0.5 mr-2" src="/bunny-mono.png" alt="Pancakeswap logo" /> -->
                        <img class="opacity-70 w-5 h-5 p-0.5 mr-2 scale-[1.6]" src="/uniswap.svg" alt="Pancakeswap logo" />
                        <!-- Buy on PancakeSwap -->
                        Buy on Uniswap
                      </button>
                    </a>

                    <!--<a href="https://coinmarketcap.com/currencies/oxbull-tech/" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70" aria-hidden="true" />
                        CoinMarketCap
                      </button>
                    </a>

                    <a href="https://www.coingecko.com/en/coins/oxbull-tech" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <!~~ <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> ~~>
                        <img class="w-5 h-5 mr-2 opacity-70" src="/cg.svg" alt="CoinGecko logo" />
                        CoinGecko
                      </button>
                    </a>-->
                    <a href="#" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70" aria-hidden="true" />
                        CoinMarketCap
                      </button>
                    </a>

                    <a href="#" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> -->
                        <img class="w-5 h-5 mr-2 opacity-70" src="/cg.svg" alt="CoinGecko logo" />
                        CoinGecko
                      </button>
                    </a>

                    <a href="https://cryptorank.io/fundraising-platforms/oxbull" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                        <img class="w-5 h-5 mr-2 opacity-70" src="/symbol_white.png" alt="Cryptorank logo" />
                        Cryptorank
                      </button>
                    </a>

                    <a href="https://chainbroker.io/platforms/oxbull" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                        <div class="w-5 h-5 mr-2 flex justify-center items-center">
                          <img class="w-4 h-4 opacity-70" src="/chainbroker.png" alt="Chainbroker logo" />
                        </div>
                        Chainbroker
                      </button>
                    </a>
                  </section>

                  <section class="mb-2">
                    <h6 class="text-white px-2 py-1 font-semibold">Socials</h6>

                    <a href="https://twitter.com/Oxbull5" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="prime:twitter" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        X @Oxbull5
                      </button>
                    </a>

                    <a href="https://t.me/Oxbull_tech" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Official Group
                      </button>
                    </a>

                    <a href="https://t.me/Oxbull_tech_announcement" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Announcement
                      </button>
                    </a>

                    <a href="https://warpcast.com/oxbulltech" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="simple-icons:farcaster" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Farcaster @oxbulltech
                      </button>
                    </a>

                    <a href="https://www.instagram.com/oxbulltech/" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="mdi:instagram" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Instagram @oxbulltech
                      </button>
                    </a>

                    <a href="https://www.tiktok.com/@oxbull.tech" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="ic:baseline-tiktok" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Tiktok @oxbull.tech
                      </button>
                    </a>
                  </section>

                  <section class="mb-2">
                    <h6 class="text-white px-2 py-1 font-semibold">Read More</h6>

                    <a href="https://oxbull.medium.com/" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Blog on Medium
                      </button>
                    </a>

                    <a href="https://docs.oxbull.tech/" target="_blank">
                      <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-2 py-1 rounded-xl duration-100 flex justify-start items-center group">
                        <Icon name="simple-icons:gitbook" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                        Documentation
                      </button>
                    </a>
                  </section>

                  <!--<div v-for="item in solutions" :key="item.name" class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
              <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
              </div>
              <div>
                <a :href="item.href" class="font-semibold text-gray-900">
                  {{ item.name }}
                  <span class="absolute inset-0" />
                </a>
                <p class="mt-1 text-gray-600">{{ item.description }}</p>
              </div>
            </div>-->
                </div>

                <div class="w-full sticky bottom-0 p-4">
                  <button :disabled="$userDataPulling" @click="refreshData()" class="btn m-0 h-auto min-h-0 w-full bg-zinc-800 py-3 px-4 rounded-xl flex justify-between items-center disabled:bg-zinc-700 hover:bg-zinc-800">
                    <div class="flex justify-start items-center">
                      <Icon name="mdi:refresh" class="text-turquoise-500 text-xl mr-2" />
                      <span>Refresh</span>
                    </div>

                    <Icon v-if="$userDataPulling" name="mdi:loading" class="text-turquoise-500 text-xl animate-spin" />
                  </button>
                </div>
              </div>
            </HeadlessPopoverPanel>
          </transition>
        </HeadlessPopover>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();

import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
// import { getAccount, watchConnections } from "@wagmi/core";
import { queryNoCache } from "~/apollo";
import { gql } from "@apollo/client/core";
import { useClipboard } from "@vueuse/core";

const { text, copy, copied, isSupported } = useClipboard({ source: OXB_V2_ADDRESS });

import { useEventBus } from "@vueuse/core";
const bus = useEventBus("reload");

const { $accessToken, $address, $deauth, $auth, $userDataPulling, $pullUserData } = useNuxtApp();

const reauth = async () => {
  // await $deauth();
  // await $auth();
};

onMounted(() => {
  watch($accessToken, () => {
    renderHeader();
  });
});

const address = ref("");

const showRefresh = ref(false);

// const toggleRefresh = (ev) => {
//   showRefresh.value = ev;
// };

// const refreshPage = (close) => {
//   close();
//   console.log(bus);
//   bus.emit("reload", true);
// };

const accountData = ref({});

const refreshData = async () => {
  $pullUserData();
};

const renderHeader = async () => {
  // accountData.value = null;
  // if (userStore.authenticated) {
  //   let gqlResult = await queryNoCache(
  //     gql`
  //       query User($limit: Int, $after: Int) {
  //         user(limit: $limit, after: $after) {
  //           tiers
  //         }
  //       }
  //     `,
  //     {
  //       limit: 1,
  //       after: null,
  //     }
  //   );
  //   accountData.value = gqlResult["user"];
  // }
};

const navItems = [
  // {
  //   name: "home",
  //   path: "/app",
  //   icon: "entypo:home",
  //   title: "Home",
  // },
  {
    name: "account",
    path: "/app/account",
    icon: "fa-solid:user-circle",
    title: "Account",
  },
  {
    name: "claim",
    path: "/app/claim",
    icon: "mdi:coins-plus",
    title: "Claim",
  },
  {
    name: "stake",
    path: "/app/stake",
    icon: "bx:bxs-bank",
    title: "Stake",
  },
  {
    name: "calendar",
    path: "/app/calendar",
    icon: "mdi:calendar",
    title: "Calendar",
  },
  // {
  //   name: "policy",
  //   path: "/app/policy",
  //   icon: "ic:baseline-policy",
  //   title: "Tiers & Policy",
  // },

  // {
  //   name: "privateSalePass",
  //   path: "/app/private-sale-pass",
  //   icon: "mdi:passport",
  //   title: "Private Sale Pass",
  // },

  {
    name: "otc",
    path: "/app/otc",
    icon: "ant-design:shop-filled",
    title: "OTC",
  },
];

const pinnedNav = computed(() => {
  let _navItems = [...navItems];
  return _navItems.splice(0, 4);
});

// const nonPinnedNav = computed(() => {
//   let _navItems = [...navItems];
//   return _navItems.splice(4);
// });

// const currentTitle = computed( () => {
//   return navItems.find((item) => item.name === route.name).title;
// })

// const getBadge = computed(() => {
//   switch (accountData.value?.tiers) {
//     case "BSC_T1":
//       return "https://oxbull-presale.s3.amazonaws.com/tier1.svg";

//     case "BSC_T2":
//       return "https://oxbull-presale.s3.amazonaws.com/tier2.svg";

//     case "BSC_T3":
//       return "https://oxbull-presale.s3.amazonaws.com/tier3.svg";

//     default:
//       return false;
//   }
// });

// console.log(route);

// onUnmounted(() => {
//   unwatch();
// });
</script>

<style></style>
